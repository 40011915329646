<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Contatti</li>
            </ul>
            <h2>Contattaci</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div id="map">
    <iframe src="https://maps.google.com/maps?q=Archè%20srl%20via%20galileo%20galilei%20mariglianella%20Italia&t=k&z=19&ie=UTF8&iwloc=&output=embed"></iframe>
</div>

<div class="contact-area ptb-100">
    <div class="container">
        <div class="row mb-5">
            <div class="col-lg-6 col-md-12">
                <div class="contact-info">
                    <span class="sub-title">Chi siamo</span>
                    <h2>Il Migliore Ente di Formazione Accreditato e Certificato</h2>
                    <p>
                        Costituita Archè Srl – Ente di Formazione Professionale e subito <strong>accreditata Regione Campania per la formazione iniziale, continua e superiore con codice di accreditamento 02616/03/16</strong>, vanta ottima esperienza nei settori di riferimento in quanto i Soci fondatori hanno esperienza ultradecennale avendo operato per molto anni in altri Enti ed Aziende attive nei medesimi settori di attività. Tra le esperienze vantate primeggia la Formazione Professionale in tutte le tipologie di corsi finanziati ed autofinanziati ed in particolar modo nei corsi di Alta Formazione ed IFTS.
                    </p>
                    <p>
                        Archè Srl è stato partner dell’<strong>“Muse” costituita nel 2017 per la aggiudicazione di Tre corsi IFTS da progettare e realizzare dal 2017 al 2021</strong>. Partners di tale ATS sono: Università Federico II Napoli, Liceo Statale “Genovesi”, Fiavet, Mes Consulting Srl, Archè Srl, e capofila Enaip Impresa Sociale Srl.
                    </p>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="contact-info">
                    <span class="sub-title">dalla formazione alla professione</span>
                    <h2>Cosa abbiamo di Speciale?</h2>
                    <p>
                        Archè Srl nei primi mesi dell’anno 2018 ha realizzato un corso di “Collaboratore polivalente nelle strutture ricettive e ristorative” di 200 ore rivolto a Cassintegrati negli anni dal 2012 al 2014 poi disoccupati. Tale corso finanziato da Regione Campania rientrava nel Progetto denominato “Ricollocami”.
                    </p>

                    <p>
                        Nei mesi di febbraio-marzo-aprile 2019 ha realizzato il corso “Web Designer” di 200 ore rivolto a Cassintegrati negli anni dal 2012 al 2014 poi disoccupati. Tale corso finanziato da Regione Campania rientrava nel Progetto denominato “Ricollocami”.
                    </p>
                    <p>
                        Da Ottobre 2019 a Luglio 2021  impegnati nella organizzazione e realizzazione di n. 2 corsi di formazione nell’ambito del progetto ITIA della Regione Campania in partenariato con Ambito territoriale di Atripalda (Av) e precisamente “Operatore alla vinificazione” di 600 ore e “Tecnico della conduzione del vigneto e della vinificazione” di 600 ore.
                    </p>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="contact-info">
                    <span class="sub-title">Informazioni</span>
                    <h2>Mettiti in contatto</h2>
                    <p>Ci piace ascoltare i lettori. Apprezziamo davvero che tu abbia dedicato del tempo per metterci in contatto.</p>
                    <ul>
                        <li>
                            <div class="icon">
                                <i class='bx bx-map'></i>
                            </div>
                            <h3>Dove siamo</h3>
                            <p>Via Arcora, 110 Palazzo Gecos<br>80013 Casalnuovo di Napoli (NA)</p>
                        </li>
                        <li>
                            <div class="icon">
                                <i class='bx bx-phone-call'></i>
                            </div>
                            <h3>Contatti</h3>
                            <p>Mobile: <a href="tel:+3908118245718">(+39) 081-18245718</a></p>
                            <p>e-mail: <a href="mailto:info@archeservizi.it">info@archeservizi.it</a></p>
                        </li>
                        <li>
                            <div class="icon">
                                <i class='bx bx-time-five'></i>
                            </div>
                            <h3>Siamo disponibili</h3>
                            <p>Lunedì - Venerdì: 09:00 - 18:00</p>
                            <p>Sabato: 09:00 - 13:00</p>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="contact-form" *ngFor="let Image of bgImage;" style="background-image: url({{Image.img}});">
                    <h2>Come possiamo aiutarti?</h2>
                    <p>L'indirizzo email non verrà pubblicato. i campi richiesti sono contrassegnati dal simbolo*</p>
                    <form #contactForm="ngForm" (ngSubmit)="submit(contactForm.value)">
                        <div class="row">
                            <div class="col-lg-12 col-md-6">
                                <div class="form-group">
                                    <input required minlength="3" maxlength="50" ngModel name="name" type="text" #name="ngModel" class="form-control" id="name" placeholder="Nome e Cognome">
                                    <div class="alert alert-danger" *ngIf="name.touched && !name.valid">
                                        <div *ngIf="name.errors.required">Nome e Cognome richiesto.</div>
                                        <div *ngIf="name.errors.minlength">Numero minimo {{ name.errors.minlength.requiredLength }} di caratteri.</div>
                                        <div *ngIf="name.errors.maxlength">Numero massimo di 50 caratteri.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-6">
                                <div class="form-group">
                                    <input required ngModel name="email" type="text" #email="ngModel" class="form-control" id="email" placeholder="la tua e-mail">
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <input required ngModel name="number" type="text" #number="ngModel" class="form-control" id="number" placeholder="cellulare">
                                    <div class="alert alert-danger" *ngIf="number.touched && !number.valid">un contatto telefonico è obbligatorio</div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <textarea required ngModel #message="ngModel" name="message" id="message" cols="30" rows="5" class="form-control" placeholder="scrivi il tuo messaggio..."></textarea>
                                    <div class="alert alert-danger" *ngIf="message.touched && !name.valid">il messaggio è obbligatorio</div>
                                </div>
                            </div>
                            <h2 class="mt-3">Quale corso sei interessato?</h2>
                            <p>seleziona il corso interessato alla quale vuoi avere informazioni*</p>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <select name="sceltaCorso" ngModel id="sceltaCorso" #sceltaCorso="ngModel" style="display: block;
                                    width: 100%;
                                    border: none;
                                    color: var(--blackColor);
                                    background-color: #F3F6F9;
                                    height: 50px;
                                    padding-left: 15px;
                                    border-radius: 5px;
                                    font-size: var(--fontSize);
                                    font-weight: 600">
                                        <option value="{{elencoCorsiRow['corso']['titolo']}}" *ngFor="let elencoCorsiRow of elencoCorsi">{{elencoCorsiRow['corso']['titolo']}}</option>
                                      </select>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12 mt-3">
                                <button type="submit" class="default-btn" [class.disabled]="!contactForm.valid">Invia Richiesta<span></span></button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
